import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Sections components

// HelpCenter page components
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";

// Faq page code

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} my={6}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Sık Sorulan Sorular
            </MKTypography>
            {/* <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                Evde eğitmen eşliğinde online pilates ile ilgili en çok sırulan sorular
              </MKTypography>
            </MKBox> */}
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="Reformer Pilates nedir?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              Reformer Pilates, özel bir ekipman olan reformer üzerinde yapılan bir pilates türüdür.
              Bu egzersizler, kas gücünü, esnekliği ve dengeyi geliştirmeyi hedefler. Yayların
              direncini ayarlayarak çeşitli kas grupları çalıştırılır ve uzman bir eğitmen eşliğinde
              gerçekleştirilir.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates hangi faydaları sağlar?"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              Reformer Pilates, vücut farkındalığını artırır, denge ve esnekliği geliştirir, kas
              gücünü artırır, postürü iyileştirir, yaralanmayı önler, stresi azaltır ve zihinsel
              rahatlama sağlar.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates'in bel fıtığına faydaları nelerdir?"
              open={collapse === 21}
              onClick={() => (collapse === 21 ? setCollapse(false) : setCollapse(21))}
            >
              <strong>Kas Güçlendirmesi: </strong>
              <p>
                Reformer Pilates, özellikle karın ve sırt kaslarını güçlendirmeye odaklanır. Bu, bel
                çevresindeki kasların desteklenmesine ve korunmasına yardımcı olabilir.
              </p>
              <strong>Esneklik Artışı:</strong>{" "}
              <p>
                Reformer Pilates egzersizleri, vücut esnekliğini artırabilir. Bu, bel fıtığı
                semptomlarını hafifletmeye ve hareket kabiliyetini artırmaya yardımcı olabilir.
              </p>
              <strong>Duruşun İyileştirilmesi:</strong>{" "}
              <p>
                Reformer Pilates, vücut farkındalığını artırabilir ve doğru postürü teşvik edebilir.
                Doğru duruş, bel fıtığından kaynaklanan sorunları azaltmaya yardımcı olabilir.
              </p>
              <strong>Direnç Antrenmanı: </strong>
              <p>
                Reformer cihazı, yaylar ve diğer direnç elemanları ile çalışmayı içerir. Bu, kas
                gücünü artırarak bel çevresindeki stabiliteyi artırabilir.
              </p>
            </FaqCollapse>
            <FaqCollapse
              title="Reformer Pilates'in skolyoza faydaları nelerdir?"
              open={collapse === 22}
              onClick={() => (collapse === 22 ? setCollapse(false) : setCollapse(22))}
            >
              <p>
                Skolyoz, omurganın yan tarafa eğriliği ile karakterize edilen bir durumdur. Bu
                eğrilik genellikle C veya S şeklinde olabilir ve çeşitli nedenlere bağlı olarak
                ortaya çıkabilir. Skolyozun tedavisi genellikle hastanın yaşına, eğrilik derecesine
                ve semptomlara bağlı olarak değişir. Reformer Pilates, skolyozun yönetilmesine
                yardımcı olabilir, ancak bu durumda da bireyin durumunu değerlendiren bir uzmana
                danışmak önemlidir. Reformer Pilates&apos;in skolyoza potansiyel faydaları şunlar
                olabilir:
              </p>
              <p>
                <strong>Kas Güçlendirme:</strong> Reformer Pilates, özellikle karın, sırt ve omuz
                kaslarını güçlendirmeye odaklanır. Bu, omurganın etrafındaki kasların
                desteklenmesine yardımcı olabilir.
              </p>
              <p>
                <strong>Esneklik Artışı:</strong> Pilates egzersizleri, vücut esnekliğini
                artırabilir. Esneklik, omurganın daha iyi hareket etmesine ve skolyoz eğriliğinin
                yönetimine katkıda bulunabilir.
              </p>
              <p>
                <strong>Duruşun İyileştirilmesi:</strong> Reformer Pilates, vücut farkındalığını
                artırabilir ve doğru postürü teşvik edebilir. Bu, skolyoz eğriliğini düzeltmese de,
                daha iyi duruşu destekleyebilir.
              </p>
              <p>
                <strong>Koordinasyon ve Denge Gelişimi:</strong> Pilates egzersizleri, vücut
                koordinasyonunu ve dengeyi artırabilir. Bu, skolyozlu bireylerin günlük
                aktivitelerini daha etkili bir şekilde sürdürebilmelerine yardımcı olabilir.
              </p>
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates duruş bozukluğunu düzeltmeye yardımcı olur mu ? "
              open={collapse === 23}
              onClick={() => (collapse === 23 ? setCollapse(false) : setCollapse(23))}
            >
              <p>
                Reformer Pilates, çeşitli postür bozukluklarına karşı potansiyel faydalar
                sağlayabilir. Ancak, her bireyin durumu farklıdır ve bu nedenle Reformer
                Pilates&apos;in spesifik postür bozukluklarına tam olarak nasıl yardımcı olacağı
                konusunda genel ifadeler yapmak zordur. Ancak, aşağıda belirtilen postür
                bozukluklarına yönelik genel potansiyel faydaları göz önünde bulundurabiliriz:
              </p>
              <p>
                <strong>Kifoz (Kamburluk):</strong>
                Reformer Pilates, sırt ve omuz kaslarını güçlendirerek, üst sırt bölgesindeki
                kamburluğu azaltabilir.
              </p>
              <p>Esneklik artışı, omurganın düzgün hizalanmasına yardımcı olabilir.</p>
              <p>
                Core (çekirdek) kaslarının güçlendirilmesi, kifozu düzeltmeye yardımcı olabilir.
              </p>
              <p>
                <strong>Lordoz (Beldeki Aşırı İçbükeylik):</strong>
                Reformer Pilates, karın ve pelvik kaslarını güçlendirerek, beldeki aşırı içbükeyliği
                azaltabilir.
              </p>
              <p>
                Esneklik egzersizleri, bel bölgesindeki gerilimi hafifletebilir ve doğru postürü
                destekleyebilir.
              </p>
              <p>
                <strong>Skolyoz (Omurga Eğriliği):</strong>
              </p>
              <p>
                Reformer Pilates, omurganın çeşitli düzlemlerinde kas gücünü artırarak, skolyoz
                eğriliğini yönetmeye yardımcı olabilir.
              </p>
              <p>
                Esneklik ve denge egzersizleri, skolyozlu bireylerin vücutlarını daha iyi
                dengelemelerine yardımcı olabilir.
              </p>
              <p>
                <strong>Düz Sırt (Flat Back):</strong>
              </p>
              <p>
                Reformer Pilates, bel ve sırt bölgesindeki kasları güçlendirerek, düz sırt durumunu
                düzeltebilir.
              </p>
              <p>
                Esneklik artışı, omurganın doğru hizalanmasına ve postürün düzeltilmesine yardımcı
                olabilir.
              </p>
              <p>
                <strong>Baş ve Boyun Pozisyonu Sorunları:</strong>
              </p>
              <p>
                Reformer Pilates, boyun ve omuz kaslarını güçlendirerek, baş ve boyun pozisyonlarını
                düzeltebilir.
              </p>
              <p>Esneklik egzersizleri, boyun ve omuz bölgesindeki gerilimi hafifletebilir</p>
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates nasıl yapılır?"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              Reformer Pilates, özel bir reformer ekipmanı üzerinde gerçekleştirilir. Eğitmeninizin
              talimatlarına uyarak çeşitli egzersizleri yaparsınız. Reformer, yaylarla donatılmış
              bir platform ve hareketli parçalar içerir, bu da vücudu destekler ve direnç sağlar.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates kimler için uygundur?"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              Reformer Pilates, her yaş ve fitness seviyesinden kişilere uygun bir egzersizdir.
              Hamilelik gibi belirli durumlar dışında herkes Reformer Pilates yapabilir. Ancak,
              herhangi bir sağlık sorunu varsa önce doktora danışılması önemlidir.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates ile kilo verilebilir mi?"
              open={collapse === 5}
              onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
            >
              <MKBox>
                Reformer Pilates, kilo verme sürecinde genellikle kilo kaybını doğrudan tetikleyen
                bir etki yapmaz. Ancak, kilo verme ve genel sağlık üzerinde olumlu etkileri vardır.
                İşte bu konuda dikkate almanız gereken bazı noktalar:
              </MKBox>{" "}
              <br />
              <MKBox>
                Kalori Yakımı: Reformer Pilates, yoğun kardiyo egzersizleri gibi yüksek kalori yakma
                potansiyeline sahip değildir. Ancak, düzenli olarak yapıldığında, metabolizmayı
                artırabilir ve bu da dolaylı olarak kalori yakımını artırabilir.
              </MKBox>
              <br />
              <MKBox>
                Kas Kütlesi Artışı: Reformer Pilates, özellikle direnç ekibine sahiptir ve bu, kas
                kütlesini artırabilir. Kas kütlesi arttıkça, vücut daha fazla enerji harcar. Ancak,
                bu artış genellikle çok belirgin olmaz ve doğrudan kilo kaybına neden olmaz.
              </MKBox>
              <br />
              <MKBox>
                Esneklik ve Hareketlilik: Reformer Pilates, vücut esnekliğini artırabilir ve genel
                hareketliliği geliştirebilir. Bu, başka egzersizlere veya günlük aktivitelere
                katılımı artırabilir ve daha fazla kalori yakılmasına yardımcı olabilir.
              </MKBox>
              <br />
              <MKBox>
                Psikolojik Etkiler: Düzenli olarak Reformer Pilates yapmak, stresi azaltabilir,
                enerji seviyelerini yükseltebilir ve genel iyi hissettirir. Bu, motivasyonu
                artırabilir ve sağlıklı bir yaşam tarzına geçişi kolaylaştırabilir. Ancak, bu
                doğrudan kilo kaybına bağlı değildir.
              </MKBox>
              <br />
              <MKBox>
                Beslenme Rolü: Kilo verme sürecinde en etkili faktör genellikle beslenme
                alışkanlıklarıdır. Kalori alımını düzenlemek, sağlıklı beslenmek ve dengeli bir
                diyet benimsemek, kilo kaybını destekleyen temel faktörlerdir. Egzersiz bu süreci
                destekler, ancak tek başına kilo kaybını sağlamaz.
              </MKBox>
              <br />
              <MKBox>
                Sonuç olarak, Reformer Pilates sağlığı artırabilir, vücut kompozisyonunu
                düzeltebilir ve genel iyi hissettirebilir. Ancak kilo verme hedefiniz varsa, bu
                süreçte beslenme alışkanlıklarınıza odaklanmak önemlidir. Egzersiz ve beslenme
                birlikte ele alındığında en etkili sonuçları elde edebilirsiniz.
              </MKBox>
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates ne sıklıkla yapılmalıdır?"
              open={collapse === 7}
              onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
            >
              Reformer Pilates, haftada 2-3 kez yapıldığında en iyi sonuçları verir. Ancak, kişinin
              hedeflerine, fitness seviyesine ve yaşam tarzına bağlı olarak farklılık gösterebilir.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates kaç dakika sürer?"
              open={collapse === 8}
              onClick={() => (collapse === 8 ? setCollapse(false) : setCollapse(8))}
            >
              Bir Reformer Pilates dersi genellikle 45-50 dakika sürer. Ancak, ders uzunluğu
              stüdyonun politikasına ve ders içeriğine bağlı olarak değişebilir.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates ile diğer egzersizler arasındaki fark nedir?"
              open={collapse === 9}
              onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
            >
              Reformer Pilates, özel bir ekipman olan reformer üzerinde gerçekleştirilen bir pilates
              türüdür. Diğer egzersizlerden farkı, reformer ekipmanının sağladığı direnç ve
              destektir, bu da egzersizlerin etkisini artırır.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates ve mat pilates arasındaki fark nedir?"
              open={collapse === 10}
              onClick={() => (collapse === 10 ? setCollapse(false) : setCollapse(10))}
            >
              <p>
                Destekleyici Ekipman: Reformer Pilates, özel bir ekipman olan reformer üzerinde
                gerçekleştirilir. Bu ekipman, vücuda destek sağlar ve hareketleri daha kontrollü
                hale getirir. Bu sayede, özellikle başlangıç düzeyinde olanlar için hareketleri
                doğru bir şekilde uygulamak daha kolay olabilir.{" "}
              </p>
              <br />
              <p>
                Direncin Ayarlanabilir Olması: Reformer, çeşitli direnç seviyelerine ayarlanabilen
                yaylar içerir. Bu, bireylerin kendi seviyelerine uygun direnci seçerek çalışmalarını
                sağlar. Mat Pilates&apos;te ise vücut ağırlığına dayalı direnç kullanılır, bu da
                bazı kişiler için zorlayıcı olabilir.
              </p>
              <br />
              <p>
                Ekstra Desteğin Sağlanması: Reformer Pilates, hareket sırasında ekstra destek
                sağlamak amacıyla kayışlar, kollar ve baş destekleri gibi öğeleri içerir. Bu
                destekler, belirli hareketleri daha rahat ve doğru bir şekilde uygulamayı
                kolaylaştırabilir. Esneklik ve Çeşitlilik: Reformer Pilates, farklı vücut tiplerine
                ve yetenek seviyelerine uygun bir dizi egzersizi içerir. Bu, kişilere daha fazla
                çeşitlilik ve esneklik sunar. Mat Pilates ise genellikle zemine odaklanır ve bazı
                hareketlerin yapılması zor olabilir. Postür ve Hareket Bilinci: Reformer Pilates,
                vücut pozisyonu ve hareket bilincini artırmak için tasarlanmıştır.{" "}
              </p>{" "}
              <br />
              <p>
                Reformer üzerinde yapılan egzersizler, postürü iyileştirmeye ve kas grupları
                arasındaki dengeyi geliştirmeye yöneliktir. Bu, mat Pilates&apos;e kıyasla daha
                fazla dikkat ve kontrol gerektirir. Ancak, her bireyin tercihleri farklı olduğu
                için, mat Pilates veya Reformer Pilates hangisinin daha rahat olduğunu belirlemek
                kişisel bir tercih meselesi olabilir. İkisi de bedeni güçlendirmeye ve esnekliği
                artırmaya yönelik etkili egzersiz yöntemleridir.
              </p>
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates hamileler için uygundur mu?"
              open={collapse === 11}
              onClick={() => (collapse === 11 ? setCollapse(false) : setCollapse(11))}
            >
              <br />
              <p>
                Hamilelik döneminde Reformer Pilates yapmanın bir dizi faydalı etkisi olabilir.
                Ancak, her hamile kadının durumu farklı olduğu için, hamilelik sürecinde herhangi
                bir egzersiz programına başlamadan önce bir sağlık profesyoneli ile konuşmak
                önemlidir. İşte Reformer Pilates&apos;in hamile kadınlar için neden faydalı
                olabileceğine dair bazı nedenler:
              </p>
              <br />
              <p>
                <strong>Çekirdek Güçlendirme: </strong>
                Reformer Pilates, özellikle çekirdek kaslarını güçlendirmeye odaklanır. Güçlü bir
                çekirdek, hamilelik sırasında bel ve sırt ağrılarını azaltabilir ve genel postürü
                destekleyebilir.
              </p>
              <p>
                <strong>Çekirdek Güçlendirme: </strong>
                Reformer Pilates, özellikle çekirdek kaslarını güçlendirmeye odaklanır. Güçlü bir
                çekirdek, hamilelik sırasında bel ve sırt ağrılarını azaltabilir ve genel postürü
                destekleyebilir.
              </p>
              <p>
                <strong>Pelvik Tabanı Güçlendirme: </strong>
                Reformer Pilates, pelvik taban kaslarını hedefleyen egzersizleri içerir. Bu, pelvik
                tabanın güçlenmesine ve bu bölgedeki kan akışının artmasına yardımcı olabilir. Güçlü
                bir pelvik taban, doğum sırasında ve sonrasında iyileşmeyi destekleyebilir.
              </p>
              <p>
                <strong>Esneklik ve Hareketlilik: </strong>
                Reformer Pilates, esnekliği artırabilir ve genel hareketliliği iyileştirebilir. Bu,
                hamilelik sırasında vücudun daha rahat ve esnek olmasına katkıda bulunabilir.
              </p>
              <p>
                <strong>Denge ve Koordinasyon: </strong>
                Reformer Pilates, denge ve koordinasyonu geliştirmeye yardımcı olur. Hamilelikte
                artan kilo ve vücut değişiklikleri nedeniyle denge sorunları ortaya çıkabilir. Bu
                egzersiz biçimi, dengeyi iyileştirmeye ve düşme riskini azaltmaya yardımcı olabilir.
              </p>
              <p>
                <strong>Solunum Kontrolü: </strong>
                Reformer Pilates, solunum kontrolünü vurgular. Doğru solunum, sakinleşmeye, enerji
                seviyelerini artırmaya ve stresle başa çıkmaya yardımcı olabilir. Ayrıca, doğum
                sırasında etkili solunum teknikleri öğrenmek de önemlidir.
              </p>
              <p>
                <strong>Rahatlatıcı Etki: </strong>
                Reformer Pilates, rahatlatıcı bir etkiye sahip olabilir. Hamilelik süreci stresli
                olabilir, bu egzersiz biçimi ise endorfin salgısını artırarak genel ruh halini
                iyileştirebilir.
              </p>
              <p>
                <strong>Hafifletici Pozisyonlar: </strong>
                Reformer Pilates, hamile kadınlar için uygun pozisyonları içerir. Düzenli mat
                Pilates egzersizleri sırasında bazı pozisyonlar hamilelikte rahatsızlık
                verebilirken, Reformer üzerinde bu rahatsızlıkların azaltılması mümkündür.
              </p>
              <p>
                Unutulmaması gereken önemli bir nokta, her hamile kadının durumu farklıdır ve
                herhangi bir egzersiz programına başlamadan önce bir sağlık profesyoneli ile
                görüşmek önemlidir. Hamilelikte güvenli bir şekilde egzersiz yapmak, genel sağlık ve
                rahatlık için önemlidir.
              </p>
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates ve yoga arasındaki fark nedir?"
              open={collapse === 13}
              onClick={() => (collapse === 13 ? setCollapse(false) : setCollapse(13))}
            >
              Reformer Pilates, özel bir ekipman olan reformer üzerinde yapılan bir pilates türüdür.
              Yoga ise beden ve zihin üzerinde çalışmayı hedefleyen bir meditasyon ve nefes
              egzersizi sistemidir. Reformer Pilates, daha çok kas gücü ve esneklik üzerine
              odaklanırken, yoga daha çok zihinsel ve ruhsal dengeyi sağlamaya yöneliktir.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates ne kadar sürede sonuç verir?"
              open={collapse === 14}
              onClick={() => (collapse === 14 ? setCollapse(false) : setCollapse(14))}
            >
              Reformer Pilates ile ne kadar sürede sonuç alınacağı kişinin düzenliğine, hedeflerine
              ve yaşam tarzına bağlıdır. Ancak, düzenli olarak yapıldığında genellikle birkaç hafta
              içinde fark edilebilir sonuçlar alınabilir.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates ile sakatlanma riski var mı?"
              open={collapse === 15}
              onClick={() => (collapse === 15 ? setCollapse(false) : setCollapse(15))}
            >
              Reformer Pilates, doğru form ve teknikle yapıldığında sakatlanma riski minimaldir.
              Ancak, egzersizler sırasında dikkatli olunmalı ve eğitmenin talimatlarına uyulmalıdır.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates hangi ekipmanı gerektirir?"
              open={collapse === 16}
              onClick={() => (collapse === 16 ? setCollapse(false) : setCollapse(16))}
            >
              Reformer Pilates, özel bir ekipman olan reformer&apos;i gerektirir. Reformer, yaylarla
              donatılmış bir platform ve hareketli parçalar içerir, bu da egzersizlerin
              çeşitliliğini sağlar.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates nasıl bir ekipmanla yapılır?"
              open={collapse === 17}
              onClick={() => (collapse === 17 ? setCollapse(false) : setCollapse(17))}
            >
              Reformer Pilates, özel bir ekipman olan reformer üzerinde yapılır. Reformer, yaylarla
              donatılmış bir platform ve hareketli parçalar içerir, bu da egzersizlerin
              çeşitliliğini sağlar.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates hangi kas gruplarını çalıştırır?"
              open={collapse === 18}
              onClick={() => (collapse === 18 ? setCollapse(false) : setCollapse(18))}
            >
              Reformer Pilates, çeşitli kas gruplarını çalıştırır. Özellikle core kaslarını, bacak
              kaslarını, sırt kaslarını ve kol kaslarını hedefler.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates ile kalori yakılabilir mi?"
              open={collapse === 19}
              onClick={() => (collapse === 19 ? setCollapse(false) : setCollapse(19))}
            >
              Evet, Reformer Pilates, kas gücünü artırarak metabolizmayı hızlandırır ve kalori
              yakımını teşvik eder. Düzenli olarak yapıldığında kalori yakma sürecini
              destekleyebilir.
            </FaqCollapse>

            <FaqCollapse
              title="Reformer Pilates ile nasıl başlanır?"
              open={collapse === 20}
              onClick={() => (collapse === 20 ? setCollapse(false) : setCollapse(20))}
            >
              Reformer Pilates&apos;e başlamak için bir pilates stüdyosunda eğitmen eşliğinde ders
              almak en iyisidir. Eğitmen, sizin için uygun düzeyde egzersizleri belirleyecek ve
              doğru formu öğretecektir.
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
