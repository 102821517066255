/*
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";

// Fulya Aksoy Pilates examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";
// import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import FaqC from "examples/Faq";

// Routes
import routes from "routes";

function Faq() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox component="section" pt={20} pb={12}>
        {/* <Container sx={{ mb: 8 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                icon="description"
                title="Detailed Documentation"
                description="The Arctic Ocean freezes every winter and much of the sea-ice then thaws every summer, and that process will continue whatever."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                icon="support"
                title="Awesome Support"
                description="The time is now for it to be okay to be great. People in this world shun people for being great. I'm not trying, I'm not trying to do the best way."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                icon="grid_view"
                title="Styled Components"
                description="If you have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s passed."
                direction="center"
              />
            </Grid>
          </Grid>
        </Container> */}
        <Container>
          <FaqC />
        </Container>
      </MKBox>
      <MKBox pt={{ xs: 0, lg: 3 }} pb={3}>
        <SimpleFooter />
      </MKBox>
    </>
  );
}

export default Faq;
