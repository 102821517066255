/* eslint-disable react/prop-types */
import { DataGrid } from "@mui/x-data-grid";

import { useState } from "react";

// Fulya Aksoy Calendar components

// Fulya Aksoy Calendar examples
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Calendar page sections
import Box from "@mui/material/Box";

// Routes

// Images
import "assets/css/tailwind.css";
import AddInstructor from "./AddInstructor";

// const textProps = {
//   title: "Reformer Calendar",
//   subtitle:
//     "Formunuzu ve zihninizi dengede tutarak sağlıklı bir yaşam için adım atın, profesyonel eğitmenler eşliğinde Reformer Calendar Salonumuzda kendinizi keşfedin",
//   buttonColor: "red",
//   skew: {
//     color: "white",
//   },
// };

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "firstName", headerName: "İsim", width: 130 },
  { field: "lastName", headerName: "Soyisim", width: 130 },
];

const rows = [{ id: 1, lastName: "Aksoy", firstName: "Fulya", age: 23 }];

function Admin() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  function CustomTabPanel({ children, value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <Container>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto">
          <AppBar position="static">
            <Tabs
              value={activeTab}
              onChange={handleTabType}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Eğitmenler" />
              <Tab label="Eğitmen Ekle" />
            </Tabs>
          </AppBar>
          <CustomTabPanel value={activeTab} index={0}>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
              />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={1}>
            <AddInstructor />
          </CustomTabPanel>
        </Grid>
      </Container>
    </>
  );
}

export default Admin;
