/* eslint-disable react/prop-types */
import React from "react";
import { AUTH_CONTEXT_ERROR } from "../constants/Text";

export const membersContext = React.createContext({
  setMembers: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  closeMembers: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  membersState: {
    members: [],
  },
});

const { Provider } = membersContext;

export const MemberProvider = (props) => {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "SET_MEMBERS":
          return action.membersState;
        default:
          return prevState;
      }
    },
    {
      open: false,
    }
  );

  const setMembers = (membersState) => {
    dispatch({
      type: "SET_MEMBERS",
      membersState,
    });
  };

  return (
    <Provider
      value={{
        membersState: state,
        setMembers,
      }}
    >
      {props.children}
    </Provider>
  );
};
