/* eslint-disable react/prop-types */
import React from "react";
import { AUTH_CONTEXT_ERROR } from "../constants/Text";

export const loaderContext = React.createContext({
  setLoader: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  closeLoader: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  loaderState: {
    open: false,
  },
});

const { Provider } = loaderContext;

export const LoaderProvider = (props) => {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "SET_LOADER":
          return action.loaderState;
        case "CLOSE_LOADER":
          return {
            open: false,
          };
        default:
          return prevState;
      }
    },
    {
      open: false,
    }
  );

  const setLoader = (loaderState) => {
    dispatch({
      type: "SET_LOADER",
      loaderState,
    });
  };

  const closeLoader = () => {
    dispatch({
      type: "CLOSE_LOADER",
    });
  };

  return (
    <Provider
      value={{
        loaderState: state,
        setLoader,
        closeLoader,
      }}
    >
      {props.children}
    </Provider>
  );
};
