/**
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication pages components
import BasicLayout from "pages/Authentication/components/BasicLayout";

// Images
import bgImage from "assets/authentication.png";
import { useState } from "react";
import useAuth from "hooks/useAuth";
import { getUser } from "api/RestHelper";
import useLoader from "hooks/useLoader";
import useAlert from "hooks/useAlert";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "firebaseConfig";

function SignInBasic() {
  const { setLoader, closeLoader } = useLoader();
  const { setAlert } = useAlert();
  const { signIn } = useAuth();
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const onSubmit = (e) => {
    e.preventDefault();
    setLoader({ open: true });

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        closeLoader();
        // Signed in
        const user = userCredential.user;
        // @ts-ignore
        localStorage.setItem("FULYA_AKSOY_PILATES_USER_KEY", user.accessToken);
        getUser(user.accessToken, userCredential.user.uid, (response) => {
          // @ts-ignore
          signIn({ ...response.user, token: user.accessToken, uid: user.uid });
          navigate("/", { replace: true });
        });
        // signIn({
        //   uid: user.uid,
        //   displayName: user.displayName,
        //   email,
        //   // @ts-ignore
        //   token: user.accessToken,
        //   // @ts-ignore
        //   roles: user.roles,
        //   picture: user.photoURL,
        // });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        closeLoader();
        setAlert({
          open: true,
          alertDetail: {
            color: "error",
            title: "Hata",
            content: "Giriş Yapılamadı, Email ve Şifrenizi kontrol edin. ",
            icon: "warning",
          },
        });
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox onSubmit={onSubmit} component="form" role="form">
            <MKBox mb={2}>
              <MKInput
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                type="email"
                label="Email"
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                label="Şifre"
                fullWidth
              />
            </MKBox>
            <MKBox mt={4} mb={1}>
              <MKButton type="submit" color="info" fullWidth>
                Giriş Yap
              </MKButton>
            </MKBox>
            {/* <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/authentication/sign-up/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MKTypography>
              </MKTypography>
            </MKBox> */}
          </MKBox>
        </MKBox>
      </Card>
    </BasicLayout>
  );
}

export default SignInBasic;
