/*
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import AboutUsOption from "pages/LandingPages/Pilates/components/AboutUsOption";

function AboutUs() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <MKTypography variant="h1" my={1}>
              Reformer Pilates Nedir
            </MKTypography>
            <MKTypography variant="body1" color="text" mb={2}>
              Reformer pilates, özel bir ekipman olan reformer üzerinde yapılan bir pilates türüdür.
              Bu egzersizler, kas gücünü, esnekliği ve dengeyi geliştirmeyi hedefler. Yayların
              direncini ayarlayarak çeşitli kas grupları çalıştırılır ve uzman bir eğitmen eşliğinde
              gerçekleştirilir.
            </MKTypography>
            <MKTypography variant="body1" color="text" mb={2}>
              Reformer Pilates, hem fiziksel hem de zihinsel sağlık için kapsamlı bir egzersiz
              programı sunar. Düzenli olarak yapılması, vücudu güçlendirirken esnekliği artırır ve
              genel sağlığı iyileştirir.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={12} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <MKTypography variant="h1" my={1}>
              Reformer Pilates Faydaları
            </MKTypography>
            <Stack>
              <AboutUsOption
                icon="done"
                content={
                  <>
                    <MKTypography variant="title" color="text">
                      <strong>Vücut Bilinci</strong>
                    </MKTypography>
                    <br />
                    <MKTypography variant="body1" color="text">
                      Reformer Pilates, vücut farkındalığını artırır. Egzersizler sırasında,
                      vücudunuzun pozisyonunu, hareketlerini ve nefes alımınızı daha iyi
                      hissetmenizi sağlar.
                    </MKTypography>
                  </>
                }
              />
              <AboutUsOption
                icon="done"
                content={
                  <>
                    <MKTypography variant="title" color="text">
                      <strong>Denge ve Esneklik</strong>
                    </MKTypography>
                    <br />
                    <MKTypography variant="body1" color="text">
                      Reformer Pilates egzersizleri, denge ve esnekliği artırır. Bu egzersizler,
                      kasların uzanmasına ve esneklik kazanmasına yardımcı olurken, dengeyi
                      geliştirir.
                    </MKTypography>
                  </>
                }
              />
              <AboutUsOption
                icon="done"
                content={
                  <>
                    <MKTypography variant="title" color="text">
                      <strong>Kas Gücü</strong>
                    </MKTypography>
                    <br />
                    <MKTypography variant="body1" color="text">
                      Reformer Pilates, kas kuvvetini artırır. Yaylar direnç sağlar ve bu da
                      kasların güçlenmesine yardımcı olur. Özellikle merkezi (core) kaslarını
                      güçlendirir.
                    </MKTypography>
                  </>
                }
              />
              <AboutUsOption
                icon="done"
                content={
                  <>
                    <MKTypography variant="title" color="text">
                      <strong>Postürün İyileştirilmesi</strong>
                    </MKTypography>
                    <br />
                    <MKTypography variant="body1" color="text">
                      Reformer Pilates, doğru postürü teşvik eder. Bu, günlük aktivitelerde ve diğer
                      egzersizlerde daha iyi bir duruş sağlar.
                    </MKTypography>
                  </>
                }
              />
              <AboutUsOption
                icon="done"
                content={
                  <>
                    <MKTypography variant="title" color="text">
                      <strong>Yaralanma Önleme</strong>
                    </MKTypography>
                    <br />
                    <MKTypography variant="body1" color="text">
                      Reformer Pilates, sakatlanmaları önler veya iyileşme sürecini hızlandırabilir.
                      Denge, esneklik ve güç artışı, spor ve günlük aktiviteler sırasında yaralanma
                      riskini azaltabilir.
                    </MKTypography>
                  </>
                }
              />
              <AboutUsOption
                icon="done"
                content={
                  <>
                    <MKTypography variant="title" color="text">
                      <strong>Rahatlama ve Stres Azaltma</strong>
                    </MKTypography>
                    <br />
                    <MKTypography variant="body1" color="text">
                      Reformer Pilates, stresi azaltmaya yardımcı olur ve zihinsel rahatlama sağlar.
                      Egzersizler sırasında derin nefes almak ve vücudu hareket ettirmek, stres
                      seviyelerini düşürebilir.
                    </MKTypography>
                  </>
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutUs;
