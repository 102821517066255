import axios from "axios";

const getAxiosInstance = (token) => {
  const instance = axios.create({
    baseURL: "https://us-central1-fulyaaksoypilates.cloudfunctions.net/api",
    headers: { Authorization: "Bearer " + token },
  });

  return instance;
};

// const getAxiosInstanceWithoutToken = () => {
//   const instance = axios.create({
//     baseURL: "https://us-central1-fulyaaksoypilates.cloudfunctions.net/api/",
//   });

//   return instance;
// };

export const getUser = (token, id, callback, errorCallback) => {
  getAxiosInstance(token)
    .get("users/" + id)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

export const createInstructor = (token, instructor, callback) => {
  getAxiosInstance(token)
    .post("instructors", instructor)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const createMember = (token, member, callback) => {
  getAxiosInstance(token)
    .post("members", member)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};
export const getMembers = (token, callback) => {
  getAxiosInstance(token)
    .get("members")
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const createPlanType = (token, instructor, callback, errorCallback) => {
  getAxiosInstance(token)
    .post("planTypes", instructor)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error(error);
      errorCallback(error);
    });
};

export const getPlanTypes = (token, callback, errorCallback) => {
  getAxiosInstance(token)
    .get("planTypes")
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error(error);
      errorCallback(error);
    });
};
