import { useState } from "react";

import Container from "@mui/material/Container";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import "assets/css/tailwind.css";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { createInstructor } from "api/RestHelper";
import useAuth from "hooks/useAuth";
import useLoader from "hooks/useLoader";
import useAlert from "hooks/useAlert";

function AddInstructor() {
  const { authState } = useAuth();
  const { setAlert } = useAlert();
  const { setLoader, closeLoader } = useLoader();
  const { user } = authState;
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [tc, setTC] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState("pilates");

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (firstname && lastname && email && phone && tc && address && type) {
      setLoader({ open: true });
      const instructor = {
        roles: ["instructor"],
        displayName: firstname + " " + lastname,
        firstname,
        lastname,
        email,
        phone,
        tc,
        address,
        type,
      };
      createInstructor(
        user.token,
        instructor,
        () => {
          setAlert({
            open: true,
            alertDetail: {
              color: "dark",
              title: "Kayıt başarılı",
              content: "Eğitmen kaydedildi. Eğitmen şifresi mailine gönderildi.",
              icon: "warning",
            },
          });
          closeLoader();
        },
        (error) => {
          setAlert({
            open: true,
            alertDetail: {
              color: "error",
              title: "Hata",
              content: error,
              icon: "warning",
            },
          });
          closeLoader();
        }
      );
    } else {
      setAlert({
        open: true,
        alertDetail: {
          color: "error",
          title: "Hata",
          content: "Lütfen tüm alanları doldurun",
          icon: "warning",
        },
      });
    }
  };

  return (
    <>
      <Container>
        <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
          <MKTypography variant="h3" mb={1}>
            Eğitmen Bilgileri
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
          <MKBox onSubmit={onSubmit} width="100%" component="form" method="post" autocomplete="off">
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKInput
                    value={firstname}
                    onChange={(event) => setFirstname(event.target.value)}
                    variant="standard"
                    label="İsim"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    value={lastname}
                    onChange={(event) => setLastname(event.target.value)}
                    variant="standard"
                    label="Soyisim"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    variant="standard"
                    type="email"
                    label="Email Address"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    variant="standard"
                    label="Telefon"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    value={tc}
                    onChange={(event) => setTC(event.target.value)}
                    variant="standard"
                    label="TC Kimlik No"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                    variant="standard"
                    label="Adres"
                    multiline
                    fullWidth
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Tip"
                    onChange={handleChange}
                  >
                    <MenuItem value="pilates">pilates</MenuItem>
                    <MenuItem value="yoga">yoga</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" xs={12} my={2}>
                <MKButton type="submit" variant="gradient" color="dark" fullWidth>
                  Eğitmen Ekle
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </>
  );
}

export default AddInstructor;
