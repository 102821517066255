/*
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// // Fulya Aksoy Pilates components
// import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// // Images
// import post1 from "assets/images/examples/testimonial-6-2.jpg";
// import post2 from "assets/images/examples/testimonial-6-3.jpg";
// import post3 from "assets/images/examples/blog-9-4.jpg";
// import post4 from "assets/images/examples/blog2.jpg";

// Fulya Aksoy Pilates components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import headstand from "assets/headstand-fulyaaksoypilates-online-pilates.jpeg";
import swan from "assets/pilates-swan.jpg";
import pilates from "assets/blog-fulyaaksoy-online-pilates.jpg";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Son blog yazıları
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={headstand}
                title="Baş Aşağı Dönüş ve Kendi Sınırlarımı Aşmak: Bir Yolculuk Hikayesi"
                description="Hayat, karşımıza çıkan zorlukları aşma ve kişisel sınırlarımızı zorlama sürecidir. Bu yolculukta..."
                action={{
                  type: "internal",
                  route: "/blogs/headstand",
                  color: "dark",
                  label: "Devamını oku",
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={swan}
                title="Kuğu Gibi Zarif ve Güçlü: Pilates Kuğu (Pilates Swan) Hareketi
                "
                description="Pilates, vücut bilinci ve gücünü arttırmaya odaklanan bir egzersiz sistemidir. Bu sistemde yer alan ..."
                action={{
                  type: "internal",
                  route: "/blogs/pilates-swan",
                  color: "dark",
                  label: "Devamını oku",
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={pilates}
                title="Pilates: Beden, Zihin ve Ruhun Tam Koordinasyonu"
                description="Günümüzde, sağlıklı bir yaşam tarzı sürdürmek her zamankinden daha önemli hale geldi. Fiziksel sağlığımızı sürdürmek..."
                action={{
                  type: "internal",
                  route: "/blogs/pilates",
                  color: "dark",
                  label: "Devamını oku",
                }}
              />
            </MKBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image="https://images.unsplash.com/photo-1498677231914-50deb6ba4217?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
                title="Cozy Spots"
                description="If you're more passionate about founding a business than the business itself technology."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image="https://images.unsplash.com/photo-1587578932405-7c740a762f7f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
                title="Home Office"
                description="Website visitors today demand a frictionless user expericence — especially when using search."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image="https://images.unsplash.com/photo-1589884629108-3193400c7cc9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
                title="Virtual Office"
                description="If you're more passionate about founding a business than the business itself, you can fall into."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image="https://images.unsplash.com/photo-1527192491265-7e15c55b1ed2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
                title="Co-working Spaces"
                description="Smart money is just dumb money that's been through a crash. Business than the business itself."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image="https://images.unsplash.com/photo-1461988625982-7e46a099bf4f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
                title="Private Space"
                description="Technology is not only the thing that moves the human race forward, but it's the only thing that has."
                action={actionProps}
              />
            </MKBox>
          </Grid>
       */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
