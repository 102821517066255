/* eslint-disable react/prop-types */
import React from "react";
import { AUTH_CONTEXT_ERROR } from "../constants/Text";

export const alertContext = React.createContext({
  setAlert: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  closeAlert: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  alertState: {
    open: false,
  },
});

const { Provider } = alertContext;

export const AlertProvider = (props) => {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "SET_ALERT":
          return action.alertState;
        case "CLOSE_ALERT":
          return {
            open: false,
          };
        default:
          return prevState;
      }
    },
    {
      open: false,
    }
  );

  const setAlert = (alertState) => {
    dispatch({
      type: "SET_ALERT",
      alertState,
    });
  };

  const closeAlert = () => {
    dispatch({
      type: "CLOSE_ALERT",
    });
  };

  return (
    <Provider
      value={{
        alertState: state,
        setAlert,
        closeAlert,
      }}
    >
      {props.children}
    </Provider>
  );
};
