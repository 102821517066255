/*
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import image from "assets/blog-fulyaaksoy-online-pilates.jpg";

function Information() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MKTypography variant="h3" mb={3}>
            Pilates: Beden, Zihin ve Ruhun Tam Koordinasyonu
          </MKTypography>
          <MKTypography variant="body1" my={3}>
            Günümüzde, sağlıklı bir yaşam tarzı sürdürmek her zamankinden daha önemli hale geldi.
            Fiziksel sağlığımızı sürdürmek, zihinsel dengeyi korumak ve ruhsal huzuru bulmak
            arasındaki dengeyi bulmak ise sıklıkla göz ardı edilen bir konudur. İşte tam bu noktada,
            Joseph Pilates&apos;in öncülük ettiği bir egzersiz metodu devreye giriyor: Pilates.
            Pilates, beden, zihin ve ruhun tam bir koordinasyonunu hedefleyen bu egzersiz sistemi,
            birçok kişi için sağlıklı bir yaşam tarzını desteklemenin anahtarı olmuştur.
          </MKTypography>
          <MKTypography variant="h4" mb={1}>
            Pilates: Beden, Zihin ve Ruhun Tam Koordinasyonu
          </MKTypography>
          <MKTypography variant="body1" my={3}>
            Pilates, bedeninizi güçlendirmek ve esnekliğini artırmak için özel olarak tasarlanmış
            bir dizi egzersiz içerir. Bu egzersizler, kasları uzatırken aynı zamanda güçlendirmeye
            odaklanır. Bu, vücudu dengelemeye ve doğru postürü korumaya yardımcı olur.
            Pilates&apos;te kullanılan kontrollü ve düzenli hareketler, kasları daha bilinçli bir
            şekilde çalıştırmanıza olanak tanır ve böylece bedeninizi daha iyi anlamanıza yardımcı
            olur.
          </MKTypography>

          <MKTypography variant="h4" mb={1}>
            Zihin: Farkındalığı Artırmak{" "}
          </MKTypography>
          <MKTypography variant="body1" my={3}>
            Pilates, sadece fiziksel sağlığı değil, aynı zamanda zihinsel sağlığı da hedefler. Her
            bir hareket, bedenle zihin arasındaki bağlantıyı güçlendirmeye yöneliktir. Hareketlerin
            dikkatlice ve kontrollü bir şekilde yapılması, zihinsel farkındalığı artırır. Bu da
            stresi azaltabilir, zihinsel berraklığı artırabilir ve genel ruh halini iyileştirebilir.
          </MKTypography>
          <MKTypography variant="h4" mb={1}>
            Ruh: Huzur ve Dengede Kalma{" "}
          </MKTypography>
          <MKTypography variant="body1" my={3}>
            Pilates, sadece fiziksel ve zihinsel sağlığı değil, aynı zamanda ruhsal sağlığı da
            destekler. Hareketler sırasında odaklanma ve derin nefes alma, içsel huzur ve dinginlik
            sağlamaya yardımcı olabilir. Pilates pratiği, ruhsal dengeyi korumak ve güçlendirmek
            için bir araç olabilir, bu da günlük yaşamın getirdiği stresle başa çıkmada etkili
            olabilir.
          </MKTypography>

          <Grid container spacing={3} alignItems="center" sx={{ mb: { xs: 6, md: 0 } }}>
            <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
              <MKBox p={2}>
                <MKBox
                  component="img"
                  src={image}
                  alt="reformer pilates, fulya aksoy, online pilates"
                  title="Reformer Pilates Denge Fulya Aksoy"
                  width="100%"
                  borderRadius="xl"
                  maxHeight="37.5rem"
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={5} mr={{ xs: 0, lg: "auto" }} position="relative">
              <MKTypography variant="h4" mb={1}>
                Ruh: Huzur ve Dengede Kalma{" "}
              </MKTypography>
              <MKTypography variant="body1" my={3}>
                Pilates, sadece fiziksel ve zihinsel sağlığı değil, aynı zamanda ruhsal sağlığı da
                destekler. Hareketler sırasında odaklanma ve derin nefes alma, içsel huzur ve
                dinginlik sağlamaya yardımcı olabilir. Pilates pratiği, ruhsal dengeyi korumak ve
                güçlendirmek için bir araç olabilir, bu da günlük yaşamın getirdiği stresle başa
                çıkmada etkili olabilir.
              </MKTypography>
            </Grid>
          </Grid>

          <MKTypography variant="h4" mb={1}>
            Pilates ve Tam Koordinasyon{" "}
          </MKTypography>
          <MKTypography variant="body1" my={3}>
            Pilates, beden, zihin ve ruh arasındaki bağlantıyı güçlendiren bir egzersiz sistemidir.
            Bu, sadece fiziksel sağlığı değil, aynı zamanda zihinsel ve ruhsal sağlığı da kapsar.
            Pilates pratiği, kişinin kendini daha iyi hissetmesine ve günlük yaşamın getirdiği
            zorluklarla başa çıkmasına yardımcı olabilir. Sonuç olarak, Pilates beden, zihin ve
            ruhun tam bir koordinasyonunu hedefler. Bu egzersiz sistemini düzenli olarak uygulamak,
            sadece fiziksel sağlığı değil, aynı zamanda içsel dengeyi ve huzuru da artırabilir.
            Bedenin, zihnin ve ruhun bir bütün olarak ele alındığı bu benzersiz metodun, sağlıklı
            bir yaşam tarzını sürdürmek isteyenler için mükemmel bir seçenek olduğunu söylemek
            mümkündür.{" "}
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
