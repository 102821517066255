/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/*
=========================================================
* Fulya Aksoy Calendar - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";

// Fulya Aksoy Calendar components

// Fulya Aksoy Calendar examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Calendar page sections
import Box from "@mui/material/Box";

// Routes
import routes from "routes";
import Instructors from "./Instructors";
import PlanTypes from "./PlanTypes";
import Members from "./Members";
import { getPlanTypes, getMembers } from "api/RestHelper";
// Images
import "assets/css/tailwind.css";
import usePlanTypes from "hooks/usePlanTypes";
import useAuth from "hooks/useAuth";
import useAlert from "hooks/useAlert";
import useMembers from "hooks/useMembers";

// const textProps = {
//   title: "Reformer Calendar",
//   subtitle:
//     "Formunuzu ve zihninizi dengede tutarak sağlıklı bir yaşam için adım atın, profesyonel eğitmenler eşliğinde Reformer Calendar Salonumuzda kendinizi keşfedin",
//   buttonColor: "red",
//   skew: {
//     color: "white",
//   },
// };

function Admin() {
  const { setPlanTypes } = usePlanTypes();
  const { setMembers } = useMembers();
  const { authState } = useAuth();
  const { setAlert } = useAlert();

  const { user } = authState;

  useEffect(() => {
    getPlanTypes(
      user.token,
      (response) => {
        setPlanTypes(response);
      },
      (error) => {
        setAlert({
          open: true,
          alertDetail: {
            color: "error",
            title: "Hata",
            content: error,
            icon: "warning",
          },
        });
      }
    );
    getMembers(
      user.token,
      (response) => {
        setMembers(response);
      },
      (error) => {
        setAlert({
          open: true,
          alertDetail: {
            color: "error",
            title: "Hata",
            content: error,
            icon: "warning",
          },
        });
      }
    );
  }, []);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  function CustomTabPanel({ children, value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{}}>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <DefaultNavbar routes={routes} />

      <Container>
        <Grid container item justifyContent="center" pt={10} xs={12} lg={12} mx="auto">
          <AppBar position="static">
            <Tabs
              orientation="vertical"
              value={activeTab}
              onChange={handleTabType}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Yönetim Anasayfa" />
              <Tab label="Takvim" />
              <Tab label="Üyeler" />
              <Tab label="Plan Tipleri" />
              <Tab label="Eğitmenler" />
            </Tabs>
          </AppBar>
          <CustomTabPanel value={activeTab} index={0}>
            Item One
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={1}>
            Item Two
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={2}>
            <Members />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={3}>
            <PlanTypes />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={4}>
            <Instructors />
          </CustomTabPanel>
        </Grid>
      </Container>
    </>
  );
}

export default Admin;
