/* eslint-disable react/prop-types */
import React from "react";
import { AUTH_CONTEXT_ERROR } from "../constants/Text";

export const planTypesContext = React.createContext({
  setPlanTypes: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  closePlanTypes: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  planTypesState: {
    planTypes: [],
  },
});

const { Provider } = planTypesContext;

export const PlanTypesProvider = (props) => {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "SET_PLAN_TYPES":
          return action.planTypesState;
        default:
          return prevState;
      }
    },
    {
      open: false,
    }
  );

  const setPlanTypes = (planTypesState) => {
    dispatch({
      type: "SET_PLAN_TYPES",
      planTypesState,
    });
  };

  return (
    <Provider
      value={{
        planTypesState: state,
        setPlanTypes,
      }}
    >
      {props.children}
    </Provider>
  );
};
