/*
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import image from "assets/pilates-swan.jpg";

function Information() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <MKTypography
          component="h6"
          variant="button"
          opacity={0.7}
          textTransform="uppercase"
          fontWeight="bold"
        >
          Swan
        </MKTypography>
        <MKTypography variant="h3" mb={3}>
          Kuğu Gibi Zarif ve Güçlü: Pilates Kuğu (Pilates Swan) Hareketi
        </MKTypography>
        <MKTypography variant="body1" my={3}>
          Pilates, bedenimizi zihnimizle uyumlu hale getiren, her seviyeden insanın
          faydalanabileceği bir egzersiz sistemidir. Bu sistemdeki egzersizler, kasları
          güçlendirmenin ve esnetmenin yanı sıra, dengeyi ve koordinasyonu da geliştirmeye yardımcı
          olur. Pilates kuğu hareketi de bu egzersizlerden biridir. Bu yazıda, Swan hareketinin
          nasıl yapıldığını ve hangi faydaları sunduğunu inceleyeceğiz.
        </MKTypography>
        <MKTypography variant="h5" mb={2}>
          Swan Hareketi Nedir?
        </MKTypography>
        <MKTypography variant="body1">
          Swan (Kuğu) hareketi, tüm vücudu çalıştıran ve özellikle sırt, göğüs ve karın kaslarını
          güçlendiren bir pilates egzersizidir. Aynı zamanda omurgayı esnetip gererek postürün
          düzelmesine yardımcı olur.
        </MKTypography>
        <Grid container spacing={3} alignItems="center" sx={{ mb: { xs: 6, md: 0 } }}>
          <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
            <MKBox p={2}>
              <MKBox
                component="img"
                src={image}
                alt="Pilates Swan Hareketi"
                title="Pilates Swan Hareketi"
                width="100%"
                borderRadius="xl"
                maxHeight="37.5rem"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={5} mr={{ xs: 0, lg: "auto" }} position="relative">
            <MKTypography variant="h5" mb={1}>
              Nasıl Yapılır?
            </MKTypography>

            <MKTypography variant="h6" mb={1}>
              1. Başlangıç Pozisyonu:
            </MKTypography>
            <MKTypography variant="body2" my={3}>
              Mat üzerinde yüzüstü uzanın.
              <br />
              Bacaklarınızı birbirine paralel olarak uzatın, ayak parmak uçları yere doğru
              bakmalıdır.
            </MKTypography>
            <MKTypography variant="h6" mb={1}>
              2. Eller ve Kollar:
            </MKTypography>
            <MKTypography variant="body2" my={3}>
              Ellerinizi omuz genişliğinde açın ve ellerinizi omuz hizasına yerleştirin.
              <br />
              Avuç içleriniz yere bakmalı, parmak uçlarınız öne doğru bakmalıdır.{" "}
            </MKTypography>
            <MKTypography variant="h6" mb={1}>
              3. Başlangıç Hareketi:
            </MKTypography>
            <MKTypography variant="body2" my={3}>
              Nefes alarak, karnınızı sıkın ve başınızı, omuzlarınızı yere iterek kaldırın.
              Omurganızı uzatın ve başınızı arkaya doğru hareket ettirin.
              <br />
              Kollarınızı düzleştirin, ancak omuzlarınızı kulaklarınızdan uzak tutun.{" "}
            </MKTypography>
            <MKTypography variant="h6" mb={1}>
              4. Uzatma ve Yükselme:
            </MKTypography>
            <MKTypography variant="body2" my={3}>
              Nefes vererek, başınızı ve göğsünüzü daha da yukarı kaldırın.
              <br />
              Sırtınızı güçlü bir şekilde uzatarak &quot;Swan&quot; pozisyonunu oluşturun.
              <br />
              Kollarınız ve bacaklarınız düz bir çizgi oluşturmalıdır.{" "}
            </MKTypography>
            <MKTypography variant="h6" mb={1}>
              5. İniş:
            </MKTypography>
            <MKTypography variant="body2" my={3}>
              Nefes alarak başlangıç pozisyonuna dönün ve vücudunuzu yavaşça mat üzerine indirin.
            </MKTypography>
          </Grid>
        </Grid>
        <MKTypography variant="h5" mb={1}>
          Swan Hareketinin Faydaları:
        </MKTypography>

        <MKTypography variant="h6" mb={1}>
          Üst Vücut Gücü:
        </MKTypography>
        <MKTypography variant="body2" my={3}>
          Swan hareketi, omuzlar, sırt ve boyun bölgesindeki üst vücut kaslarını güçlendirir.
        </MKTypography>
        <MKTypography variant="h6" mb={1}>
          Esneklik:
        </MKTypography>
        <MKTypography variant="body2" my={3}>
          Bu hareket, omurga esnekliğini artırarak vücut hareket açıklığını destekler.{" "}
        </MKTypography>
        <MKTypography variant="h6" mb={1}>
          Postür İyileştirmesi
        </MKTypography>
        <MKTypography variant="body2" my={3}>
          Swan hareketi, doğru bir duruşu teşvik eder ve sırtın düzgün bir şekilde hizalanmasına
          yardımcı olur.{" "}
        </MKTypography>
        <MKTypography variant="h6" mb={1}>
          Sırt Ağrılarına Karşı:
        </MKTypography>
        <MKTypography variant="body2" my={3}>
          Düzenli olarak yapıldığında, Swan hareketi sırt ağrılarını azaltabilir ve sırt kaslarını
          güçlendirebilir.{" "}
        </MKTypography>
        <MKTypography variant="h6" mb={1}>
          Kuğu Hareketi Yaparken Dikkat Edilmesi Gerekenler:
        </MKTypography>
        <MKTypography variant="body2" my={3}>
          Belinizi yerden kaldırmamaya dikkat edin.
        </MKTypography>
        <MKTypography variant="body2" my={3}>
          Karın kaslarınızı sıkı tutun.
        </MKTypography>
        <MKTypography variant="body2" my={3}>
          Boynunuzun çok fazla gerilmesine izin vermeyin.
        </MKTypography>
        <MKTypography variant="body2" my={3}>
          Nefesinizi tutmayın.
        </MKTypography>
        <MKTypography variant="h6" mb={1}>
          Kuğu Hareketi Modifikasyonları:
        </MKTypography>
        <MKTypography variant="body2" my={3}>
          Başlangıçta, bacaklarınızı bükerek ve dizlerinizi yere yaklaştırarak hareketi
          yapabilirsiniz.
        </MKTypography>
        <MKTypography variant="body2" my={3}>
          Daha fazla destek için ellerinizi yerin altına koyabilirsiniz.
        </MKTypography>
        <MKTypography variant="body2" my={3}>
          Hareketi zor buluyorsanız, başlangıçta daha az tekrar yapabilirsiniz.
        </MKTypography>
        <MKTypography variant="body1" my={3}>
          Pilates pratiğinizde Swan hareketine yer vererek, vücudunuzun üst bölgesini
          güçlendirebilir, esnekliğini artırabilir ve genel postürünüzü iyileştirebilirsiniz. Ancak
          herhangi bir egzersiz programına başlamadan önce, uzman bir sağlık profesyoneli veya
          Pilates eğitmeni ile görüşmek önemlidir. Unutmayın ki, herkesin vücut yapısı farklıdır ve
          bireysel ihtiyaçlara uygun bir egzersiz programı belirlenmelidir.
        </MKTypography>
      </Container>
    </MKBox>
  );
}

export default Information;
