/* eslint-disable react/prop-types */
import { DataGrid } from "@mui/x-data-grid";
import MKButton from "components/MKButton";
import React, { useState } from "react";

// Fulya Aksoy Calendar components

// Fulya Aksoy Calendar examples
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Calendar page sections
import Box from "@mui/material/Box";

// Routes

// Images
import "assets/css/tailwind.css";
import AddMember from "./AddMember";

// const textProps = {
//   title: "Reformer Calendar",
//   subtitle:
//     "Formunuzu ve zihninizi dengede tutarak sağlıklı bir yaşam için adım atın, profesyonel eğitmenler eşliğinde Reformer Calendar Salonumuzda kendinizi keşfedin",
//   buttonColor: "red",
//   skew: {
//     color: "white",
//   },
// };

function RenderButton(props) {
  const { hasFocus, value } = props;
  const buttonElement = React.useRef(null);
  const rippleRef = React.useRef(null);

  React.useLayoutEffect(() => {
    if (hasFocus) {
      const input = buttonElement.current.querySelector("input");
      input?.focus();
    } else if (rippleRef.current) {
      // Only available in @mui/material v5.4.1 or later
      rippleRef.current.stop({});
    }
  }, [hasFocus]);

  return (
    <strong>
      {value?.getFullYear() ?? ""}
      <MKButton
        ref={buttonElement}
        touchRippleRef={rippleRef}
        variant="contained"
        size="small"
        style={{ marginLeft: 16 }}
        // Remove button from tab sequence when cell does not have focus
        tabIndex={hasFocus ? 0 : -1}
        onKeyDown={(event) => {
          if (event.key === " ") {
            // Prevent key navigation when focus is on button
            event.stopPropagation();
          }
        }}
      >
        Open
      </MKButton>
    </strong>
  );
}

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "firstName", headerName: "İsim", width: 130 },
  { field: "lastName", headerName: "Soyisim", width: 130 },
  { field: "phone", headerName: "Telefon", width: 130 },
  {
    field: "show",
    headerName: "Görüntüle",
    width: 150,
    renderCell: RenderButton,
  },
];

const rows = [{ id: 1, firstName: "asd", lastName: "asd", phone: "1111111111" }];

function Admin() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  function CustomTabPanel({ children, value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <Container>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto">
          <AppBar position="static">
            <Tabs
              value={activeTab}
              onChange={handleTabType}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Üyeler" />
              <Tab label="Üye Ekle" />
            </Tabs>
          </AppBar>
          <CustomTabPanel value={activeTab} index={0}>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
              />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={1}>
            <AddMember />
          </CustomTabPanel>
        </Grid>
      </Container>
    </>
  );
}

export default Admin;
