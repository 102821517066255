// @mui icons

import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsappIcon from "@mui/icons-material/WhatsApp";

// Fulya Aksoy Pilates components
import MKTypography from "components/MKTypography";

// Images

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Fulya Aksoy Pilates",
    route: "/",
  },
  socials: [
    {
      icon: <InstagramIcon />,
      text: "@fulyaaksoypilates",
      link: "https://www.instagram.com/fulyaaksoypilates/",
    },
    {
      icon: <WhatsappIcon />,
      text: "05445420278",
      link: "https://wa.me/905445420278",
    },
  ],
  menus: [],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}
    </MKTypography>
  ),
};
