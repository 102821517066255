export const USER_KEY = "FULYA_AKSOY_PILATES_USER_KEY";

export const onSignIn = (user) => {
  localStorage.USER_KEY = JSON.stringify(user);
};
export const updatee = (user) => {
  localStorage.USER_KEY = JSON.stringify(user);
};

export const onSignOut = () => delete localStorage.USER_KEY;

export const isSignedIn = () => {
  if (localStorage.USER_KEY) {
    return { user: JSON.parse(localStorage.USER_KEY), signedIn: true };
  } else {
    return { user: undefined, signedIn: false };
  }
};

export const getUser = () => {
  return JSON.parse(localStorage.USER_KEY);
};
