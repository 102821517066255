import { useState } from "react";

import Container from "@mui/material/Container";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import "assets/css/tailwind.css";
import Grid from "@mui/material/Grid";
import { createMember } from "api/RestHelper";
import useAuth from "hooks/useAuth";
import useLoader from "hooks/useLoader";
import useAlert from "hooks/useAlert";

function AddMember() {
  const { authState } = useAuth();
  const { setAlert } = useAlert();
  const { setLoader, closeLoader } = useLoader();
  const { user } = authState;
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [tc, setTC] = useState("");
  const [address, setAddress] = useState("");
  const [blood, setBlood] = useState("");
  const [contactPhone, setContoctPhone] = useState("");
  const [contactName, setContoctName] = useState("");
  const [healthNotes, setHealthNotes] = useState("");
  const [activities, setActivities] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    if (firstname && lastname && email && phone && tc) {
      setLoader({ open: true });
      const member = {
        roles: ["member"],
        displayName: firstname + " " + lastname,
        firstname,
        lastname,
        email,
        phone,
        tc,
        address,
        blood,
        contactPhone,
        contactName,
        healthNotes,
        activities,
      };
      createMember(
        user.token,
        member,
        () => {
          setAlert({
            open: true,
            alertDetail: {
              color: "dark",
              title: "Kayıt başarılı",
              content: "Üye kaydedildi.",
              icon: "warning",
            },
          });
          closeLoader();
        },
        (error) => {
          setAlert({
            open: true,
            alertDetail: {
              color: "error",
              title: "Hata",
              content: error,
              icon: "warning",
            },
          });
          closeLoader();
        }
      );
    } else {
      setAlert({
        open: true,
        alertDetail: {
          color: "error",
          title: "Hata",
          content: "Lütfen tüm alanları doldurun",
          icon: "warning",
        },
      });
    }
  };

  return (
    <>
      <Container>
        <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
          <MKTypography variant="h3" mb={1}>
            Üye Bilgileri
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
          <MKBox onSubmit={onSubmit} width="100%" component="form" method="post" autocomplete="off">
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKInput
                    value={firstname}
                    onChange={(event) => setFirstname(event.target.value)}
                    variant="standard"
                    label="İsim"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    value={lastname}
                    onChange={(event) => setLastname(event.target.value)}
                    variant="standard"
                    label="Soyisim"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    variant="standard"
                    type="email"
                    label="Email Address"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    variant="standard"
                    label="Telefon"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    value={tc}
                    onChange={(event) => setTC(event.target.value)}
                    variant="standard"
                    label="TC Kimlik No"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                    variant="standard"
                    label="Adres"
                    multiline
                    fullWidth
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    value={blood}
                    onChange={(event) => setBlood(event.target.value)}
                    variant="standard"
                    label="Kan Grubu"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <MKInput
                    value={contactName}
                    onChange={(event) => setContoctName(event.target.value)}
                    variant="standard"
                    label="Acil Durum İletişim İsim"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <MKInput
                    value={contactPhone}
                    onChange={(event) => setContoctPhone(event.target.value)}
                    variant="standard"
                    label="Acil Durum İletişim Telefon"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    value={healthNotes}
                    onChange={(event) => setHealthNotes(event.target.value)}
                    variant="standard"
                    label="Sağlık Durumu"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    value={activities}
                    onChange={(event) => setActivities(event.target.value)}
                    variant="standard"
                    label="Sportif Aktivite Geçmişi"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" xs={12} my={2}>
                <MKButton type="submit" variant="gradient" color="dark" fullWidth>
                  Üye Ekle
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </>
  );
}

export default AddMember;
