/**
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useCallback } from "react";
import MKSnackbar from "components/MKSnackbar";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Fulya Aksoy Pilates themes
import theme from "assets/theme";
import Presentation from "layouts/pages/landing-pages/pilates";

// Fulya Aksoy Pilates routes
import routes from "routes";
import Article from "pages/Blogs/Article";
import Swan from "pages/Blogs/Swan";
import Pilates from "pages/Blogs/Pilates";
import Signin from "layouts/authentication/sign-in/basic";

import useAuth from "hooks/useAuth";
import { isSignedIn } from "auth";
import RequireAuth from "RequireAuth";
import useAlert from "hooks/useAlert";
import useLoader from "hooks/useLoader";

export default function App() {
  const { pathname } = useLocation();

  const { authState, signIn, signOut } = useAuth();
  const { alertState, closeAlert } = useAlert();
  const { loaderState } = useLoader();
  const { open, alertDetail } = alertState;
  const openLoader = loaderState.open;
  const { user } = authState;
  const checkSession = (u) => {
    if (u) {
      // @ts-ignore
      let seconds = Math.abs(new Date().getTime() - new Date(u.lastSignInTime).getTime()) / 1000;
      if (seconds > 3600) {
        signOut();
        //navigate("/giris", { replace: true });
        return false;
      }
      return true;
    }
  };
  const init = useCallback(() => {}, [user]);
  useEffect(() => {
    if (user !== undefined) {
      init(user);
    } else {
      const { user, signedIn } = isSignedIn();
      if (user && signedIn) {
        const sessionIn = checkSession(user);
        if (sessionIn) {
          signIn(user);
          init(user);
        }
      }
    }
  }, [user]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.requireAuth) {
          return (
            <Route
              key={route.key}
              path={route.route}
              element={<RequireAuth>{route.component}</RequireAuth>}
            />
          );
        } else {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route exact path="/" element={<Presentation />} />
        <Route exact path="/giris" element={<Signin />} />
        {/* <Route path="/presentation" element={<Presentation />} /> */}
        <Route path="/blogs/headstand" element={<Article />} />
        <Route path="/blogs/pilates-swan" element={<Swan />} />
        <Route path="/blogs/pilates" element={<Pilates />} />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
      {open && (
        <MKSnackbar
          color={alertDetail.color}
          icon="notifications"
          title={alertDetail.title}
          content={alertDetail.content}
          dateTime=""
          open={open}
          close={closeAlert}
        />
      )}
      {openLoader && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </ThemeProvider>
  );
}
