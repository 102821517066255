/*
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useRef, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
// import Stack from "@mui/material/Stack";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";

// Fulya Aksoy Pilates examples
import DefaultFooter from "examples/Footers/SimpleFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Pilates page sections
import AboutUs from "pages/LandingPages/Pilates/sections/AboutUs";
import Faq from "examples/Faq";
import Information from "pages/LandingPages/Pilates/sections/Information";
import Testimonials from "pages/LandingPages/Pilates/sections/Testimonials";

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

// Images
import "assets/css/tailwind.css";
import bgImage from "assets/fulyaaksoypilatesintrovideo.mp4";
// const textProps = {
//   title: "Reformer Pilates",
//   subtitle:
//     "Formunuzu ve zihninizi dengede tutarak sağlıklı bir yaşam için adım atın, profesyonel eğitmenler eşliğinde Reformer Pilates Salonumuzda kendinizi keşfedin",
//   buttonColor: "red",
//   skew: {
//     color: "white",
//   },
// };

function Pilates() {
  const vidRef = useRef();
  useEffect(() => {
    vidRef.current.load();
    vidRef.current.addEventListener("canplaythrough", () => {
      vidRef.current.playsInline = true;
      vidRef.current.setAttribute("muted", "");
      vidRef.current.defaultMuted = true;
      vidRef.current.play();
    });
  }, []);

  return (
    <>
      {/* <MKSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color="dark"
        icon="phone"
        title="Detaylı bilgi için iletişime geçin"
        content={
          <>
            <a href="tel:05445420278" target="_blank" rel="noreferrer">
              05445420278
            </a>
            <br />
            <a href="https://wa.me/905445420278" target="_blank" rel="noreferrer">
              WhatsApp
            </a>
            <br />
            <a href="https://www.instagram.com/fulyaaksoypilates/" target="_blank" rel="noreferrer">
              Instagram
            </a>
            <br />
            <a href="https://maps.app.goo.gl/MVSYxh1juckCpfdJ7" target="_blank" rel="noreferrer">
              Google Maps
            </a>
          </>
        }
        open={show}
        close={toggleSnackbar}
      /> */}

      <DefaultNavbar routes={routes} sticky />
      <div
        style={{
          position: "relative",
          paddingTop: "56.25%",
        }}
      >
        <video
          src={bgImage}
          ref={vidRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          muted
          autoPlay
          loop
        />
        {/* <ReactPlayer
          muted={true}
          playing={true}
          loop={true}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
          url={bgImage}
          width="100%"
          height="100%"
        /> */}
      </div>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -4,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />

        <Testimonials />
        <AboutUs />

        <Faq />
      </Card>

      <MKBox pt={3} px={1} mt={3}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Pilates;
