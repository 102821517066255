/*
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Fulya Aksoy Pilates examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/SimpleFooter";

// About Us page sections
import Information from "pages/Blogs/Article/sections/Information";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/PhotoReal_headstand_yoga_1.jpg";

import { Helmet } from "react-helmet";

function Article() {
  const headerRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Fulya Aksoy PilatesBaş Aşağı Dönüş ve Kendi Sınırlarımı Aşmak: Bir Yolculuk Hikayesi
        </title>
      </Helmet>
      <MKBox bgColor="white" py={0.25}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://www.instagram.com/fulyaaksoypilates/",
            label: "İletişim",
            color: "success",
          }}
          transparent
          relative
        />
      </MKBox>
      <MKBox
        ref={headerRef}
        minHeight="85vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Headstand
            </MKTypography>
            {/* <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
              The time is now for it be okay to be great. People in this world shun people for being
              nice.
            </MKTypography>
            <MKTypography variant="h5" color="white" mt={2} mb={1}>
              Connect with us on
            </MKTypography> */}
            <MKBox display="flex" alignItems="center">
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://www.instagram.com/p/CzYIp-qMKah/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
                mr={3}
              >
                <i className="fab fa-instagram" /> Görüntüle
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Article;
