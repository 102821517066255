/*
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

// Fulya Aksoy Pilates examples

function Information() {
  return (
    <MKBox component="section" py={{ xs: 6, md: 12 }}>
      <Container>
        <Grid
          container
          item
          justifyContent="left"
          xs={12}
          lg={12}
          sx={{ mx: "auto", pb: 3, textAlign: "left" }}
        >
          <MKTypography variant="body1" color="text">
            Sevgili ziyaretçi, <br />
          </MKTypography>
          <MKTypography variant="body1" color="text">
            <br />
            Sağlıklı bir yaşam tarzına adım atmanın vakti geldi! Fulya Aksoy Pilates, sizi en iyi
            versiyonunuza ulaştırmak için burada. İşte neden bize katılmanız gerektiğine dair birkaç
            sebep:
          </MKTypography>
          <Stack>
            <AboutUsOption
              content={
                <MKTypography variant="body1" color="text">
                  <strong>Özel ve Bireysel Yaklaşım:</strong> <br /> Biz her bireyin eşsiz olduğuna
                  inanıyoruz. Uzman antrenörlerimiz, ihtiyaçlarınıza ve hedeflerinize uygun kişiye
                  özel programlar oluşturarak sizinle birlikte çalışacak.
                </MKTypography>
              }
            />

            <AboutUsOption
              content={
                <MKTypography variant="body1" color="text">
                  <strong>Modern Ekipmanlar ve Uzmanlık:</strong> <br /> En son Pilates ekipmanları
                  ile donatılmış stüdyomuzda, sertifikalı eğitmenlerimizle çalışırken vücudunuzun
                  sınırlarını zorlayacak ve güçlendirecek bir deneyim yaşayacaksınız.
                </MKTypography>
              }
            />
            <AboutUsOption
              content={
                <MKTypography variant="body1" color="text">
                  <strong>Motivasyon ve Topluluk Desteği:</strong> <br /> Fulya Aksoy Pilates,
                  sadece bir spor salonu değil, aynı zamanda bir aile ortamıdır. Diğer üyelerimizle
                  birlikte hedeflerinize ulaşırken, birbirinize motivasyon kaynağı olacaksınız.
                </MKTypography>
              }
            />
            <AboutUsOption
              content={
                <MKTypography variant="body1" color="text">
                  <strong>Esnek Programlar:</strong> <br /> Yoğun bir programınız varsa bile
                  endişelenmeyin. Esnek ders saatleri ve çeşitli programlarımız ile yaşam tarzınıza
                  uygun bir plan oluşturabiliriz.
                </MKTypography>
              }
            />
            <AboutUsOption
              content={
                <MKTypography variant="body1" color="text">
                  <strong>Deneme Dersi İmkanı:</strong> <br /> Bizimle tanışın ve stüdyomuzdaki
                  atmosferi deneyimleyin. İlk dersinizi ücretsiz olarak alın ve ne kadar fark
                  yaratabileceğimizi görün.
                </MKTypography>
              }
            />
          </Stack>
          <MKTypography variant="body1" color="text">
            Sağlığınıza yatırım yapma zamanı geldi. Hemen bugün bize katılın ve hayalinizdeki
            sağlıklı yaşama adım atın!
          </MKTypography>
          <br />

          <MKTypography variant="body1" color="text">
            <br />
            Sevgiler,
            <br />
            Fulya Aksoy
            <br />
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
