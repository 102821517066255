// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCWti3jGoAL6RQFun2wrkxv6qdsIczD2Iw",
  authDomain: "fulyaaksoypilates.firebaseapp.com",
  projectId: "fulyaaksoypilates",
  storageBucket: "fulyaaksoypilates.appspot.com",
  messagingSenderId: "622697100882",
  appId: "1:622697100882:web:0fddeff82b48a561cf118b",
  measurementId: "G-WBY9KMB51F",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
const auth = getAuth(app);
auth.onIdTokenChanged(async (user) => {
  if (user) {
    window.localStorage.setItem("idToken", await user.getIdToken());
  }
});
export { auth };
