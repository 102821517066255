/**
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";

// Fulya Aksoy Pilates base styles
import typography from "assets/theme/base/typography";
// import whatsapp from "assets/images/logos/whatsapp.png";
// import instagram from "assets/images/logos/Instagram_icon.png";
// import phone from "assets/images/logos/phone.png";
// import email from "assets/images/logos/email.png";
const asd =
  '<iframe width="100%" height="300px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.525189270807!2d32.76624537647766!3d39.97436727151413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3496656d860ff%3A0x2605cf63428cdda5!2sFulya%20Aksoy%20Pilates!5e0!3m2!1str!2str!4v1708625380444!5m2!1str!2str"></iframe>';

function Iframe(props) {
  // eslint-disable-next-line react/prop-types
  return <div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />;
}
function SimpleFooter({ light }) {
  const { size } = typography;

  // const renderLinks = () =>
  //   links.map((link, key) => (
  //     <MKBox
  //       key={link.name}
  //       component="li"
  //       pl={key === 0 ? 0 : 2}
  //       pr={key === links.length - 1 ? 0 : 2}
  //       lineHeight={1}
  //     >
  //       <Link href={link.href} target="_blank">
  //         <MKTypography variant="button" fontWeight="regular" color={light ? "white" : "text"}>
  //           {link.name}
  //         </MKTypography>
  //       </Link>
  //     </MKBox>
  //   ));

  return (
    <Container>
      <MKBox component="section" py={3}>
        <MKBox pb={3} px={1} mb={3}>
          <MKBox style={{ position: "relative" }}>
            <Iframe iframe={asd} />
          </MKBox>
        </MKBox>
        <Container>
          <Grid container spacing={3} item xs={12} lg={9} sx={{ mx: "auto" }}>
            <Grid key="instagram" item xs={6} md={3} ml={{ xs: 0, lg: "auro" }}>
              <MKTypography
                component="h6"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                Instagram
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                <MKBox key="{name}" component="li" p={0} m={0} lineHeight={1.25}>
                  <MKTypography
                    href="https://www.instagram.com/fulyaaksoypilates/"
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    variant="button"
                    color="secondary"
                    fontWeight="regular"
                    pb={0.5}
                  >
                    #fulyaaksoypilates
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
            <Grid key="whatsapp" item xs={6} md={3} ml={{ xs: 0, lg: "auro" }}>
              <MKTypography
                component="h6"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                Whatsapp
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                <MKBox key="{name}" component="li" p={0} m={0} lineHeight={1.25}>
                  <MKTypography
                    href="https://wa.me/905445420278"
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    variant="button"
                    color="secondary"
                    fontWeight="regular"
                    pb={0.5}
                  >
                    05445420278
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
            <Grid key="phone" item xs={6} md={3} ml={{ xs: 0, lg: "auro" }}>
              <MKTypography
                component="h6"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                Telefon Et
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                <MKBox key="{name}" component="li" p={0} m={0} lineHeight={1.25}>
                  <MKTypography
                    href="tel:05445420278"
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    variant="button"
                    color="secondary"
                    fontWeight="regular"
                    pb={0.5}
                  >
                    05445420278
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
            <Grid key="email" item xs={12} md={3} ml={{ xs: 0, lg: "auro" }}>
              <MKTypography
                component="h6"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                Email Gönder
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                <MKBox key="{name}" component="li" p={0} m={0} lineHeight={1.25}>
                  <MKTypography
                    href="mailto:bilgi@fulyaaksoypilates.com.tr"
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    variant="button"
                    color="secondary"
                    fontWeight="regular"
                    pb={0.5}
                  >
                    bilgi@fulyaaksoypilates.com.tr
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
            <Grid key="address" item xs={12} md={6} ml={{ xs: 0, lg: "auro" }}>
              <MKTypography
                component="h6"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                Adres
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                <MKBox key="{name}" component="li" p={0} m={0} lineHeight={1.25}>
                  <MKTypography
                    href="https://maps.app.goo.gl/F63BxoqKiQKvkW146"
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    variant="button"
                    color="secondary"
                    fontWeight="regular"
                    pb={0.5}
                  >
                    Mehmet Akif Ersoy Mah. Mehmet Akif Ersoy Caddesi 328. No: 2F Yenimahalle /
                    ANKARA
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>

            {/* 
            <Grid item xs={12} md={4}>
              <DefaultBackgroundCard
                // image={instagram}
                label=""
                title="fulyaaksoypilates"
                description=""
                action={{
                  type: "external",
                  route: "https://www.instagram.com/fulyaaksoypilates/",
                  label: "INSTAGRAM",
                }}
              />
            </Grid> */}

            {/* <Grid item xs={12} md={4}>
              <DefaultBackgroundCard
                // image={whatsapp}
                label=""
                title="05445420278"
                description=""
                action={{
                  type: "external",
                  route: "https://wa.me/905445420278",
                  label: "WHATSAPP",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DefaultBackgroundCard
                // image={phone}
                label=""
                title="05445420278"
                description=""
                action={{
                  type: "external",
                  route: "tel:05445420278",
                  label: "TELEFON",
                }}
              />
            </Grid> */}
            {/* <Grid item xs={12} md={4}>
              <DefaultBackgroundCard
                // image={email}
                label=""
                title="bilgi@fulyaaksoypilates.com.tr"
                description=""
                action={{
                  type: "external",
                  route: "mailto:bilgi@fulyaaksoypilates.com.tr",
                  label: "email",
                }}
              />
            </Grid> */}
            {/* <Grid item xs={12} md={8}>
              <DefaultBackgroundCard
                // image={map}
                label=""
                title="Mehmet Akif Ersoy Mah. Mehmet Akif Ersoy Caddesi 328. No: 2F Yenimahalle / ANKARA"
                description=""
                action={{
                  type: "external",
                  route: "https://maps.app.goo.gl/F63BxoqKiQKvkW146",
                  label: "HARİTA",
                }}
              />
            </Grid> */}
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        width="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color={light ? "white" : "text"}
          fontSize={size.sm}
        >
          &copy; {new Date().getFullYear()}, made with
          <MKBox fontSize={size.md} color={light ? "white" : "text"} mb={-0.5} mx={0.25}>
            <Icon color="inherit" fontSize="inherit">
              favorite
            </Icon>
          </MKBox>
          {/* by
          <Link href={href} target="_blank">
            <MKTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
              &nbsp;{name}&nbsp;
            </MKTypography>
          </Link> */}
          {/* for a better web. */}
        </MKBox>
        {/* <MKBox
          component="ul"
          sx={({ breakpoints }) => ({
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            mt: 3,
            mb: 0,
            p: 0,

            [breakpoints.up("lg")]: {
              mt: 0,
            },
          })}
        >
          {renderLinks()}
        </MKBox> */}
      </MKBox>
    </Container>
  );
}

// Setting default values for the props of SimpleFooter
SimpleFooter.defaultProps = {
  company: { href: "https://www.creative-tim.com/", name: "Creative Tim" },
  links: [
    { href: "https://www.creative-tim.com/", name: "Creative Tim" },
    { href: "https://www.creative-tim.com/presentation", name: "About Us" },
    { href: "https://www.creative-tim.com/blog", name: "Blog" },
    { href: "https://www.creative-tim.com/license", name: "License" },
  ],
  light: false,
};

// Typechecking props for the SimpleFooter
SimpleFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  light: PropTypes.bool,
};

export default SimpleFooter;
