/*
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Fulya Aksoy Pilates pages
import Photos from "pages/LandingPages/Photos";

export default function RentalPage() {
  return <Photos />;
}
