/*
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import image from "assets/headstand-fulyaaksoypilates-online-pilates.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MKTypography
            component="h6"
            variant="button"
            opacity={0.7}
            textTransform="uppercase"
            fontWeight="bold"
          >
            Headstand
          </MKTypography>
          <MKTypography variant="h3" mb={3}>
            Baş Aşağı Dönüş ve Kendi Sınırlarımı Aşmak: Bir Yolculuk Hikayesi
          </MKTypography>
          <MKTypography variant="body1" my={3}>
            Hayat, karşımıza çıkan zorlukları aşma ve kişisel sınırlarımızı zorlama sürecidir. Bu
            yolculukta kendi sınırlarımızı keşfetmek, korkularımızla yüzleşmek ve başarıyı yakalamak
            adına adımlar atmaktır. Kendi öykemde, baş aşağı duruş yaparken duvarın olmamasından
            duyduğum korkudan, bu korkuyu aşarak yükselebildiğim noktaya kadar olan süreci paylaşmak
            istiyorum.
            <br /> <br />
            Bir zamanlar, baş aşağı duruş yaparken duvarın olmamasından dolayı duyduğum korku, beni
            adeta bir zincirle bağlamış gibiydi. Baş aşağı duruş yapmak, fiziksel bir egzersizden
            çok daha fazlasını temsil ediyordu benim için. Bu durum, kendi sınırlarımın ve
            inançlarımın ne kadar sıkı olduğunu görmeme neden oldu. Ancak, bu korkunun üstesinden
            gelme kararı aldığımda, içimde beklenmedik bir güç ortaya çıktı.
            <br /> <br />
            İlk adım, kendime güvenmeye karar vermekte yattı. Başkalarının başarı hikayelerini
            duyduğumda veya gördüğümde, &quot;Bu benim için değil&quot; ya da &quot;Bunu asla
            başaramam&quot; gibi düşünceler içinde kayboluyordum. Ancak, bir gün içsel bir değişim
            yaşandı ve kendime inanmaya başladım. Baş aşağı duruş yapabilirim, sadece kendime
            güvenirsem.
          </MKTypography>

          <Grid container spacing={3} alignItems="center" sx={{ mb: { xs: 6, md: 0 } }}>
            <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
              <MKBox p={2}>
                <MKBox
                  component="img"
                  src={image}
                  alt="headstand, fulya aksoy, online pilates"
                  title="Headstand Fulya Aksoy Pilates"
                  width="100%"
                  borderRadius="xl"
                  maxHeight="37.5rem"
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={5} mr={{ xs: 0, lg: "auto" }} position="relative">
              <MKTypography variant="body1" my={3}>
                İnançlarımı değiştirmek, kendime olan bakış açımı dönüştürmek için bir dönüm
                noktasıydı. Kendi potansiyelimin farkına vardım ve bu potansiyeli gerçekleştirmek
                için çaba harcamaya başladım. Her seferinde biraz daha fazla zaman geçirdim, her
                seferinde biraz daha güven kazandım. Zorluklarla karşılaştıkça, onların beni
                güçlendirdiğini fark ettim. Her düşüş, daha güçlü bir şekilde tekrar ayağa kalkmamı
                sağladı.
              </MKTypography>
            </Grid>
          </Grid>

          <MKTypography variant="body1" my={3}>
            Bu yolculuk, hayatın her alanına uygulanabilir. Sadece baş aşağı duruş yapma konusunda
            değil, aynı zamanda kariyer, ilişkiler, kişisel gelişim ve daha birçok alanda kendi
            sınırlarımızı zorlama cesaretini bulabiliriz. Başarı, sadece dışsal faktörlere bağlı
            değildir; aynı zamanda içsel bir dönüşümü de gerektirir.
            <br /> <br />
            Sonuç olarak, baş aşağı duruş yapmaktan korktuğum günlerden şimdi çok rahat yükselebilen
            bir noktaya geldim. Bu deneyim, hayatta karşılaştığım diğer zorluklarla başa çıkmam
            konusunda da bana ilham verdi. Kendi sınırlarımızı zorlayarak, korkularımızla yüzleşerek
            ve kendimize inanarak gerçek potansiyelimizi keşfetme gücümüzü hiçbir zaman
            küçümsememeliyiz. İnanınca, her şey mümkündür ve başarı kaçınılmazdır
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
