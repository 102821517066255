/* eslint-disable react/prop-types */
import { useState } from "react";

import Container from "@mui/material/Container";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import "assets/css/tailwind.css";
import Grid from "@mui/material/Grid";
import { createPlanType } from "api/RestHelper";
import useAuth from "hooks/useAuth";
import useLoader from "hooks/useLoader";
import useAlert from "hooks/useAlert";

function AddPlanType(props) {
  const { authState } = useAuth();
  const { setAlert } = useAlert();
  const { setLoader } = useLoader();
  const { user } = authState;
  const [numberOfCourse, setNumberOfCourse] = useState();
  const [price, setPrice] = useState();
  const [durationOfPlan, setDurationOfPlan] = useState();

  const onSubmit = (event) => {
    event.preventDefault();
    if (numberOfCourse && price && durationOfPlan) {
      setLoader({ open: true });
      const planType = {
        numberOfCourse,
        price,
        durationOfPlan,
      };
      createPlanType(
        user.token,
        planType,
        () => {
          setAlert({
            open: true,
            alertDetail: {
              color: "dark",
              title: "Kayıt başarılı",
              content: "Plan tipi kaydedildi.",
              icon: "warning",
            },
          });
          props.setActiveTab(0);
          setNumberOfCourse(undefined);
          setPrice(undefined);
          setDurationOfPlan(undefined);
          setLoader({ open: false });
        },
        (error) => {
          setLoader({ open: false });
          setAlert({
            open: true,
            alertDetail: {
              color: "error",
              title: "Hata",
              content: error,
              icon: "warning",
            },
          });
        }
      );
    } else {
      setAlert({
        open: true,
        alertDetail: {
          color: "error",
          title: "Hata",
          content: "Lütfen tüm alanları doldurun",
          icon: "warning",
        },
      });
    }
  };

  return (
    <>
      <Container>
        <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
          <MKTypography variant="h3" mb={1}>
            Paket Bilgileri
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
          <MKBox onSubmit={onSubmit} width="100%" component="form" method="post" autocomplete="off">
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <MKInput
                    value={numberOfCourse}
                    onChange={(event) => setNumberOfCourse(event.target.value)}
                    variant="standard"
                    label="Ders Sayısı"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <MKInput
                    value={price}
                    onChange={(event) => setPrice(event.target.value)}
                    variant="standard"
                    label="Ücret"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    value={durationOfPlan}
                    onChange={(event) => setDurationOfPlan(event.target.value)}
                    variant="standard"
                    type="number"
                    label="Paket Süresi (gün)"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" xs={12} my={2}>
                <MKButton type="submit" variant="gradient" color="dark" fullWidth>
                  Plan Tipi Ekle
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </>
  );
}

export default AddPlanType;
