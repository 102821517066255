/*
=========================================================
* Fulya Aksoy Pilates - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useCallback, useState } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";

// Fulya Aksoy Pilates components
// import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard";
import TransparentBlogCard from "../components/TransparentBlogCard";
import Carousel, { Modal, ModalGateway } from "react-images";

// Images
import desk from "assets/fulyaaksoypilates/desk.jpg";
import giris from "assets/fulyaaksoypilates/giris.jpg";
import reformer from "assets/fulyaaksoypilates/reformer.jpg";
import reformer2 from "assets/fulyaaksoypilates/reformer2.jpg";
import salon from "assets/fulyaaksoypilates/salon.jpg";
import soyunmaodası from "assets/fulyaaksoypilates/soyunmaodası.jpg";
import tuvalet from "assets/fulyaaksoypilates/tuvalet.jpg";
import tuvalet2 from "assets/fulyaaksoypilates/tuvalet2.jpg";

const itemData = [
  {
    src: desk,
    title: "Giriş Masa",
  },
  {
    src: giris,
    title: "Giriş",
  },
  {
    src: reformer,
    title: "Reformer",
  },
  {
    src: reformer2,
    title: "Reformer 2",
  },
  {
    src: salon,
    title: "Pilates Stüdyo",
  },
  {
    src: soyunmaodası,
    title: "Soyunma Odası",
  },
  {
    src: tuvalet,
    title: "Tuvalet",
  },
  {
    src: tuvalet2,
    title: "Duş",
  },
];
function Places() {
  // const actionProps = {
  //   type: "internal",
  //   route: "/pages/landing-pages/rental",
  //   color: "info",
  //   label: "from / night",
  // };

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {itemData.map((item, index) => {
            return (
              <Grid key={"photoGrid" + index + item.title} item xs={12} md={6} lg={4}>
                <MKBox key={"MKBox" + index + item.title} mt={3}>
                  <TransparentBlogCard
                    key={"TransparentBlogCard" + index + item.title}
                    onClick={(event) => {
                      openLightbox(event, { index });
                    }}
                    image={item.src}
                    title={item.title}
                  />
                </MKBox>
              </Grid>
            );
          })}
        </Grid>

        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={itemData.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Container>
    </MKBox>
  );
}

export default Places;
