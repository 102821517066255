/* eslint-disable react/prop-types */
import { useState } from "react";

// Fulya Aksoy Calendar components

// Fulya Aksoy Calendar examples
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Calendar page sections
import Box from "@mui/material/Box";

// Routes

// Images
import "assets/css/tailwind.css";
import AddPlanType from "./AddPlanType";

function Admin() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  function CustomTabPanel({ children, value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <Container>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto">
          <AppBar position="static">
            <Tabs
              value={activeTab}
              onChange={handleTabType}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Paketler" />
              <Tab label="Paket Ekle" />
            </Tabs>
          </AppBar>
          <CustomTabPanel value={activeTab} index={0}>
            asd
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={1}>
            <AddPlanType setActiveTab={setActiveTab} />
          </CustomTabPanel>
        </Grid>
      </Container>
    </>
  );
}

export default Admin;
