/* eslint-disable react/prop-types */
import React from "react";
import { AUTH_CONTEXT_ERROR } from "../constants/Text";
import { onSignIn, onSignOut } from "auth";
export const authContext = React.createContext({
  signIn: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  signOut: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  authState: {
    user: undefined,
    isLoading: true,
    isSignout: false,
    isAuthenticated: false,
  },
});

const { Provider } = authContext;

export const AuthProvider = (props) => {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "SIGN_IN":
          return {
            ...prevState,
            isSignout: false,
            user: action.user,
            isAuthenticated: true,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isSignout: true,
            user: undefined,
            isAuthenticated: false,
          };
        default:
          return prevState;
      }
    },
    {
      user: undefined,
      token: undefined,
    }
  );

  const signIn = (user) => {
    onSignIn(user);
    dispatch({
      type: "SIGN_IN",
      user,
    });
  };

  const signOut = async () => {
    await onSignOut();
    dispatch({ type: "SIGN_OUT" });
  };

  return (
    <Provider
      value={{
        authState: state,
        signIn,
        signOut,
      }}
    >
      {props.children}
    </Provider>
  );
};
